<template>
  <div :style="{'background-color':sfondo_3}">
    <!-- Immagine Contatti Desktop-->
    <div class="section-img-contatti">
      <img
          class="background-img-contatti-altair"
          id="img-faq"
          src="https://funer24-cdn.com/pexels-alex-andrews-821754.jpg"
          alt="contatti_altair">
    </div>
    <LogoAgency class="hide-mobile"></LogoAgency>

    <!-- Contatti -->
    <div class="container-80 line-services contatti-line">
      <span :style="{'color':testo_sfondo_3}">Contatti</span>
      <hr :style="{'backgroundColor':testo_sfondo_3}">
      <div class="square" :style="{'backgroundColor':testo_sfondo_3}"></div>
    </div>
    <!-- Sede Principale -->
    <div class="container-80">
      <div class="all-agency-contatti">
        <div class="item-agency-contatti">
          <div class="info-agency-contatti">
            <div class="sede-contatti" :style="{'color':testo_sfondo_3}">
              <span class="info-contatti-title">Sede Principale:</span>
              <span class="info-contatti-text" :style="{'opacity':0.4, 'color':testo_sfondo_3}">{{ via }} - {{
                  cap
                }} {{ comune }}</span>
            </div>
            <div class="telefono-contatti" :style="{'color':testo_sfondo_3}">
              <span class="info-contatti-title">Telefono:</span>
              <span class="info-contatti-text" :style="{'opacity':0.4, 'color':testo_sfondo_3}">{{ telefono }}</span>
            </div>
            <div v-if="cellulari !== undefined && cellulari.length > 0" class="cellulare-contatti"
                 :style="{'color':testo_sfondo_3}">
              <span class="info-contatti-title">Cellulare:</span>
              <span v-for="item in cellulari" :key="item.id" class="info-contatti-text"
                    :style="{'opacity':0.4, 'color':testo_sfondo_3}">{{ item.referente }} {{ item.cellulare }}</span>
            </div>
            <div class="email-contatti" :style="{'color':testo_sfondo_3}">
              <span class="info-contatti-title">Email:</span>
              <span class="info-contatti-text" :style="{'opacity':0.4, 'color':testo_sfondo_3}">{{ email }}</span>
            </div>

          </div>
          <div class="maps-contatti">
            <l-map
                class="maps-contatti-agency"
                style="height: 300px;"
                :zoom="zoom"
                :center="geolocation"
                gestureHandling
                :options="mapOptions"
            >
              <l-tile-layer
                  :url="url"
                  :attribution="attribution">
              </l-tile-layer>
              <l-marker :lat-lng="geolocation"></l-marker>
            </l-map>

          </div>
        </div>
      </div>
    </div>
    <!-- Altre Sedi -->
    <div class="container-80">
      <div class="all-agency-contatti" v-for="(item) in sedi" :key="item.id">
        <hr :style="{'background-color':sfondo_1}">
        <div class="item-agency-contatti">
          <div class="info-agency-contatti">
            <div v-if="item.address" class="sede-contatti" :style="{'color':testo_sfondo_3}">
              <span class="info-contatti-title">Sede:</span>
              <span class="info-contatti-text" :style="{'opacity':0.4}">{{ item.address }} </span>
            </div>
            <div v-if="item.telefono" class="telefono-contatti" :style="{'color':testo_sfondo_3}">
              <span class="info-contatti-title">Telefono:</span>
              <span class="info-contatti-text" :style="{'opacity':0.4}">{{ item.telefono }}</span>
            </div>
            <div v-if="item.agenzie.cellulare" class="cellulare-contatti" :style="{'color':testo_sfondo_3}">
              <span class="info-contatti-title">Cellulare:</span>
              <span class="info-contatti-text" :style="{'opacity':0.4}">{{ item.agenzie.cellulare }}</span>
            </div>
            <div v-if="item.agenzie.email" class="email-contatti" :style="{'color':testo_sfondo_3}">
              <span class="info-contatti-title">Email:</span>
              <span class="info-contatti-text" :style="{'opacity':0.4}">{{ item.agenzie.email }}</span>
            </div>

          </div>
          <div class="maps-contatti">
            <l-map
                class="maps-contatti-agency"
                style="height: 300px;"
                :zoom="zoom"
                gestureHandling
                :options="mapOptions"
                :center="getNormalizedGeolocation(item.geolocation)"
            >
              <l-tile-layer
                  :url="url"
                  :attribution="attribution">
              </l-tile-layer>
              <l-marker :lat-lng="getNormalizedGeolocation(item.geolocation)"></l-marker>
            </l-map>

          </div>
        </div>
        <!-- <hr :style="{'background-color':sfondo_1}"> -->
      </div>
    </div>
  </div>
</template>


<script>
import {GestureHandling} from "leaflet-gesture-handling";
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';
import {mapGetters} from "vuex";
import LogoAgency from './LogoAgency.vue';
import {
  normalizeGeolocation
} from "@/utils/util";

export default {
  name: "Contatti",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    // eslint-disable-next-line vue/no-unused-components
    GestureHandling,
    LogoAgency,
  },

  data() {
    return {
      url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFyb2NjbzEyIiwiYSI6ImNsazc1ejNyaDA3NzkzdHA2cmppNWN4MzUifQ.PGAGDXVwWq6njX4L3EUw7w',
      attribution: '',
      zoom: 17,
      mapOptions: {
        zoomSnap: 0.5,
        gestureHandling: true
      }
    }
  },

  computed: {
    ...mapGetters([
      'texture',
      'nome_azienda',
      'sfondo_1',
      'sfondo_2',
      'sfondo_3',
      'testo_sfondo_3',
      'testo_sfondo_1',
      'testo_sfondo_2',
      'sedi',
      'cellulari',
      'manifesti',
      'necrologi',
      'chi_siamo',
      'nome_azienda',
      'cap',
      'telefono',
      'comune',
      'via',
      'email',
      'template',
      'geolocation',
      'icone',
      'sedi',
      'servizi',
      'foto_azienda',
      'foto_homepage',
    ]),
  },
  metaInfo() {
    return {
      title: 'Contatti - ' + this.nome_azienda,
      meta: [
        {
          name: 'description',
          content: ''
        },
        {property: 'og:title', content: 'Contatti'},
        {property: 'og:site_name', content: ''},
        {
          property: 'og:description',
          content: ''
        },
        {property: 'og:type', content: ''},
        {property: 'og:url', content: ''},
        {property: 'og:image', content: ''}
      ]
    }
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {

    getNormalizedGeolocation(geolocation) {
      return normalizeGeolocation(geolocation)
    },

    handleScroll() {
      if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
        document.getElementById("img-contatti-background").style.marginTop = "0px";
      } else {
        document.getElementById("img-contatti-background").style.marginTop = "80px";
      }
    },

  },
}
</script>


<style scoped>
@import '~@/assets/css/template_altair/contatti.css';
</style>
